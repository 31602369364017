.ant-btn+.ant-btn {
  margin-left: 8px;
}

.ant-card {
  border-radius: 10px !important;
}

.ant-card-actions {
  border-radius: 0 0 10px 10px !important;
}

.cursor-point {
  cursor: pointer;
}

.popover-infos-box {
  margin: 0 !important;
  padding: 0 !important;
}

//
//.ant-layout{
//  background-color: #f3f5f9 !important;
//}
//.ant-layout-content{
//}
//.ant-modal-header {
//  padding-top: 0;
//}
//.ant-modal-content {
//  padding: 15px!important;
//}
//.ant-input-border {
//  .ant-input {
//    border: 1px solid #d9d9d9!important;
//  }
//}
//.ant-modal-confirm .ant-modal-body {
//  padding: 15px!important;
//}
//.ant-modal-close {
//  right: 10px!important;
//  top: 10px!important;
//}
//.ant-modal-close, .ant-modal-close-icon {
//  width: 30px!important;
//  height: 30px!important;
//  display: flex;
//  justify-content: center;
//  align-items: center;
//}
//.ant-modal-close-x {
//  width: 30px;
//  height: 30px;
//}
.ant-modal-header {
  margin-bottom: 18px !important;
}

.f-13{
  font-size: 13px !important;
}
.f-14{
  font-size: 14px !important;
}
.w-100 {
  width: 100%;
}
.text-center {
  text-align: center;
}
.button-flex {
  display: flex;
  justify-content: flex-end;
}

.flex-center {
  display: flex;
  justify-content: center;
}

.flex-left {
  display: flex;
  justify-content: left;
}

.ant-modal {
  top: 20%;
}

.ant-dropdown-menu-item {
  padding: 5px 0 !important;
}

.ant-radio-checked {
  .ant-radio-inner {
    border-color: #1677ff !important;
    background: #1677ff !important;
  }
}

.input-disabled {
  background-color: #fff !important;
  border: none !important;

  .ant-input {
    color: rgba(0, 0, 0, 0.85) !important;
  }

  .ant-input-affix-wrapper-disabled {
    background-color: #fff !important;
    border: none !important;
  }
}

// .css-tndfrw-Layout{
//   box-shadow: 0px 0px 10px 0px rgba(10,43,92,0.05);
// }

// tabs
// .ant-tabs {
//   .ant-tabs-tab-btn {
//     color: @primary_color !important;
//     font-size: 16px;
//   }
//   .ant-tabs-tab-active {
//     .ant-tabs-tab-btn {
//       color: @blue_color !important;
//     }
//   }
//   .ant-tabs-ink-bar {
//     background: @blue_color !important;
//   }
// }