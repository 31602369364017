@primary_color: #1858f0;
@light_origin_color: #f49800;
@ORIGIN_color: #ee7500;
@light_blue_color: #6ba1ff;
@medium_blue_color: #0d9eff;
@blue_color: #1858f0;
@font_color: #0a2b5c;
@import url('./antd-reset.less');

body {
  margin: 0;
  font-family: Mulish-Bold, Mulish, -apple-system, BlinkMacSystemFont,
    'Segoe UI', 'Roboto', 'Oxygen', 'Ubuntu', 'Cantarell', 'Fira Sans',
    'Droid Sans', 'Helvetica Neue', sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  min-width: 1280px;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

html,
body,
p,
ol,
ul,
li,
dl,
dt,
dd,
blockquote,
figure,
fieldset,
legend,
textarea,
pre,
iframe,
hr,
h1,
h2,
h3,
h4,
h5,
h6 {
  margin: 0;
  padding: 0;
}

@primary-color: #1858f0;