.App {
  text-align: center;
}
.App-logo {
  height: 40vmin;
  pointer-events: none;
}
@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}
.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}
.ant-menu-submenu .ant-menu-item-selected {
  background-color: #f8fafe !important;
}
.ant-menu-submenu-popup {
  top: 55px !important;
}
.ant-layout-header {
  z-index: 3 !important;
}
.App-link {
  color: #61dafb;
}
.ant-layout{
  background: #eeeff0;
}
@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}
.w-100{
  width: 100% !important;
}
.mb-0 {
  margin-bottom: 0px !important;
}
.mb-1 {
  margin-bottom: 4px !important;
}
.mb-2 {
  margin-bottom: 8px !important;
}
.mb-3 {
  margin-bottom: 20px !important;
}
.mb-4 {
  margin-bottom: 40px !important;
}
.mt-1 {
  margin-top: 4px !important;
}
.mr-1 {
  margin-right: 4px !important;
}
.mt-2 {
  margin-top: 10px !important;
}
.mr-2 {
  margin-right: 10px !important;
}
.mt-3 {
  margin-top: 20px !important;
}
.ml-1 {
  margin-left: 10px !important;
}
.ml-2 {
  margin-left: 20px !important;
}
.ml-3 {
  margin-left: 30px !important;
}
.mt-4 {
  margin-top: 40px !important;
}
.mt-5 {
  margin-top: 50px !important;
}
.mt-6 {
  margin-top: 60px !important;
}
.pt-1 {
  padding-top: 5px !important;
}
.pt-2 {
  padding-top: 10px !important;
}
.m-0 {
  margin: 0px !important;
}
.mr-0 {
  margin-right: 0px !important;
}
.ml-0 {
  margin-left: 0px !important;
}
.mt-0 {
  margin-top: 0px !important;
}
.mb-0 {
  margin-bottom: 0px !important;
}
.f12 {
  font-size: 12px !important;
}
.f13 {
  font-size: 13px !important;
}
.f14 {
  font-size: 14px !important;
}
.f15 {
  font-size: 15px !important;
}
.f16 {
  font-size: 16px !important;
}
.flip-horizontal{
  transform: scaleX(-1);
}
.text-bold {
  font-weight: bold;
}
.text-center {
  text-align: center;
}
.picker-center input {
  text-align: center;
}
.text-gray {
  color: #7e93b6;
}
.text-success {
  color: #009688;
}
.text-red {
  color: #ff4d4f;
}
.text-blue {
  color: #3a6eec;
}
.text-right {
  text-align: right !important;
}
.box-shadow {
  box-shadow: 0px 6px 10px 0px rgba(10, 43, 92, 0.05);
}
.box-shadow-dark {
  box-shadow: 0px 6px 10px 0px rgba(10, 43, 92, 0.15) !important;
}
.border-none {
  border: none !important;
}
.border-none .ant-select-selector {
  border: none !important;
}
.pre-wrap {
  white-space: pre-wrap;
}
.gap-5{
  gap:5px;
}
.gap-10{
  gap:10px;
}
.d-flex {
  display: flex;
}
.flex-1{
  flex: 1;
}
.hover-red:hover{
  color:#ff4d4f;
}
.justify-left {
  display: flex;
  justify-content: start;
  align-items: start;
}
.justify-center {
  display: flex;
  justify-content: center;
  align-items: center;
}
.justify-space-between {
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.items-center {
  align-items: center;
}
.justify-center-flex {
  display: flex;
  align-items: stretch;
  flex-direction: column-reverse;
  flex-wrap: wrap;
  align-content: stretch;
  justify-content: center;
}
.flex-space-between {
  display: flex;
  justify-content: space-between;
}
.justify-end{
  justify-content: end;
}
.pointer {
  cursor: pointer;
}
.ant-select .ant-select-arrow {
  font-size: 15px;
}
.text-dark {
  color: #0a2b5c !important;
}
.text-white {
  color: #FFF !important;
}
.ant-modal-root .ant-btn-primary {
  background: linear-gradient(150deg, #6ba1ff 0%, #1858f0);
  font-size: 16px;
  height: 40px;
  padding: 6.428571428571429px 15px;
  border-radius: 4px;
  border: none;
}
.ant-modal-root .ant-btn-default {
  background: linear-gradient(150deg, #fcfdfd 0%, #f3f3f3 100%) !important;
  font-size: 16px;
  height: 40px;
  padding: 6.428571428571429px 15px;
  border-radius: 4px;
}
.ant-modal-root .ant-modal-confirm-content {
  margin-block-start: 8px;
  flex-basis: 100%;
  max-width: calc(100%) !important;
  margin-bottom: 20px;
}

.ant-modal-root .ant-modal-confirm-title {
  flex: 0 0 100%;
  display: block;
  overflow: hidden;
  color: rgba(0, 0, 0, 0.88);
  font-weight: 600;
  font-size: 16px;
  line-height: 1.5;
  margin-bottom: 10px;
}

.ant-btn-primary {
  box-shadow: none;
}
.ant-modal-root .anticon-info-circle {
  color: #2b9be6;
}
.ant-tooltip-inner {
  white-space: pre-wrap;
}
.ant-modal-confirm-content {
  flex: 1;
}
.ant-table-row-selected {
  background: #FFF;
}
:where(.css-dev-only-do-not-override-gif59b).ant-table-wrapper .ant-table-tbody>tr.ant-table-row-selected>td {
  background: #fafbfc;
}
:where(.css-dev-only-do-not-override-gif59b).ant-table-wrapper .ant-table-tbody>tr.ant-table-row-selected>td {
  background: #fafbfc !important;
}
.required::before {
  display: inline-block;
  margin-inline-end: 4px;
  color: #ff4d4f;
  font-size: 14px;
  font-family: SimSun, sans-serif;
  line-height: 1;
  content: "*";
}
.ant-modal {
  top: 10%;
}
.ant-popover-inner {
  border-radius: 10px !important;
  width: 400px;
  max-height: 600px;
  overflow-y: auto;
}
.ant-popover-inner {
  padding: 28px !important;
}
.ant-popover-inner .ant-timeline-item-last {
  padding-bottom: 0px !important;
}
.ant-popover-inner .ant-timeline-item-last .ant-timeline-item-content {
  min-height: auto !important;
}
:where(.css-dev-only-do-not-override-1eiwymr).ant-btn-default {
  box-shadow: none;
}
:where(.css-dev-only-do-not-override-1eiwymr).ant-table-wrapper .ant-table-tbody>tr.ant-table-row-selected>td {
  background-color: #FFFFFF;
}
.loading {
  position: fixed;
  width: 100%;
  height: 100vh;
  top: 0px;
  left: 0px;
  display: flex;
  justify-content: center;
  align-items: center;
  background: rgba(0, 0, 0, 0.2);
  z-index: 1;
}
.loading .animation {
  width: 100px;
  height: 100px;
  background: #ffffff;
  border-radius: 20px;
  margin-bottom: 150px;
}
.card {
  background: rgb(255, 255, 255);
  padding: 24px;
  border-radius: 10px;
}
.relative {
  position: relative;
}
.ant-message-error{
  white-space: break-spaces;
  word-wrap: break-word;
  overflow-wrap: break-word;
}

.ant-modal .ant-modal-content{
  border-radius: 10px;
}